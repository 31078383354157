import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import logo from './UCSF_Logo.png';
import style from './style.css';

const NavBar = ({ className }) => {
    return (
        <Header
            className={className}
            logo={
                <img src={logo} alt="UCSF Health Eating Disorders Program" className={style.logo} />
            }
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
